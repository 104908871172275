import { Assignment } from '../../app/model/Assignment';
import { baseQuery } from './baseQuery';
import { Tag } from './types';
import { createApi } from '@reduxjs/toolkit/query/react';

const ASSIGNMENT_TAG_TYPE = 'Assignment' as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGNMENT_LIST_TAG = {
    type: ASSIGNMENT_TAG_TYPE,
    id: 'LIST',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateAssignmentTag = (assignment: Assignment): Tag<typeof ASSIGNMENT_TAG_TYPE> => ({
    type: ASSIGNMENT_TAG_TYPE,
    id: assignment.id,
});

export const assignmentsApi = createApi({
    reducerPath: 'assignmentsApi',
    tagTypes: [ASSIGNMENT_TAG_TYPE],
    baseQuery,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    endpoints: (builder) => ({}),
});
