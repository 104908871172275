import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Consultant } from '../../model/Consultant';
import { Formik } from 'formik';
import { AssignmentShortList } from '../assignments/AssignmentShortList';
import { AssignmentExpanded } from '../../model/Assignment';
import { getAssignmentsWithConsultants } from '../assignments/AssignmentsApi';
import { ProjectExpanded } from '../../model/Project';
import { Link } from 'react-router-dom';
import { useConsultants } from '../../customHooks/consultants';
import { useDeleteConsultantMutation } from '../../../store/api/consultants';
import { useProjectsExpanded } from '../../customHooks/projects';

interface DeleteConsultantModalProps {
    consultantToDelete?: Consultant;
    onClose: (deleted: boolean) => void;
}

export function DeleteConsultantModal(props: DeleteConsultantModalProps): React.JSX.Element {
    const [show, setShow] = useState(!!props.consultantToDelete);
    const [assignments, setAssignments] = useState<AssignmentExpanded[]>();
    const consultants = useConsultants();
    const [deleteConsultant] = useDeleteConsultantMutation();

    const projects = useProjectsExpanded();
    const managedProjects = useMemo(
        () => projects?.filter((p) => p.accountManagerId === props.consultantToDelete?.id),
        [projects, props.consultantToDelete?.id],
    );

    const loadData = (): void => {
        getAssignmentsWithConsultants(Promise.resolve(consultants)).then((allAssignments) => {
            setAssignments(allAssignments.filter((assignment) => assignment.consultantId === props.consultantToDelete?.id));
        });
    };

    const onFocus = (): void => {
        if (props.consultantToDelete) {
            loadData();
        }
    };

    useEffect(() => {
        setAssignments(undefined);
        setShow(!!props.consultantToDelete);

        if (props.consultantToDelete) {
            // use focus handler to reload data when switching tabs
            // (e.g. when editing account manager before deleting consultant)
            window.addEventListener('focus', onFocus);

            loadData();

            return () => {
                window.removeEventListener('focus', onFocus);
            };
        }
    }, [props.consultantToDelete]);

    const loading = !assignments || !managedProjects;

    const onSubmit = async (): Promise<void> => {
        try {
            await deleteConsultant(props.consultantToDelete!);
            props.onClose(true);
        } catch (error) {
            console.error('error while deleting consultant', error);
        }
    };

    const closeModal = (): void => {
        props.onClose(false);
    };

    return (
        <Modal show={show} onHide={closeModal}>
            <Formik initialValues={{}} onSubmit={onSubmit}>
                {(formProps) => (
                    <Form onSubmit={formProps.handleSubmit} noValidate>
                        <Modal.Header closeButton>
                            <Modal.Title>Consultant löschen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p>
                                        Soll der Consultant &quot;{props.consultantToDelete?.name}&quot; wirklich gelöscht werden?
                                    </p>

                                    <p>
                                        Wenn Consultants das Unternehmen verlassen sollte stattdessen ein Enddatum gesetzt werden
                                        (passiert auch automatisch durch den Jira Import). Ehemalige Consultants werden nach 3
                                        Jahren automatisch gelöscht.
                                    </p>

                                    <AssignmentShortList
                                        assignments={assignments}
                                        header={<h6>Einsätze, welche hierdurch gelöscht werden</h6>}
                                        noAssignments={<h6>Dieser Consultant hat keine Einsätze</h6>}
                                        assignmentMapper={(assignment: AssignmentExpanded) => assignment.project.name}
                                    />
                                    <ManagedProjectList managedProjects={managedProjects} />
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div>Gelöschte Consultants können nicht wiederhergestellt werden</div>
                            <div>
                                <Button variant="secondary" className="me-2" onClick={closeModal}>
                                    Abbrechen
                                </Button>

                                <Button variant="danger" type="submit" className="me-2" disabled={loading}>
                                    Löschen
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

function ManagedProjectList(props: { managedProjects?: ProjectExpanded[] }): React.JSX.Element {
    return props.managedProjects && props.managedProjects.length > 0 ? (
        <div style={{ width: '100%', overflowY: 'scroll' }} className="mt-3 mh-3">
            <h6>Projekte, in denen der Consultant Ansprechpartner ist</h6>
            <div className="m-2">
                <ul>
                    {props.managedProjects.map((project, index) => (
                        <li key={index}>
                            <div>
                                {project.name}
                                <Link className="text-info" to={`/projects/${project.id}`} target="_blank">
                                    <Button variant="link" title="Projekt bearbeiten (neuer Tab)">
                                        <span className="oi oi-pencil" />
                                    </Button>
                                </Link>
                            </div>
                        </li>
                    ))}
                </ul>
                <small className="text-muted">
                    Die Projekte werden nicht gelöscht, allerdings wird der Ansprech&shy;partner zurückgesetzt. Hier bitte
                    Übergaben durchführen!
                </small>
            </div>
        </div>
    ) : (
        <div className="w-100 mt-3 mh-3">
            <h6>Der Consultant ist kein Ansprechpartner für ein Projekt</h6>
        </div>
    );
}
