import { GetProjectsOptions, useGetProjectsQuery } from '../../store/api/projects';
import { expandProject } from '../components/projects/ProjectsApi';
import { useMemo } from 'react';
import { ProjectExpanded } from '../model/Project';
import { useGetConsultantsQuery } from '../../store/api/consultants';

export function useProjectsExpanded(initialEmptyArray: true, options?: GetProjectsOptions): ProjectExpanded[];
export function useProjectsExpanded(initialEmptyArray?: false, options?: GetProjectsOptions): ProjectExpanded[] | undefined;

export function useProjectsExpanded(initialEmptyArray?: boolean, options?: GetProjectsOptions): ProjectExpanded[] | undefined {
    const { data: projects } = useGetProjectsQuery(options);
    const { data: consultants } = useGetConsultantsQuery();

    return useMemo(() => {
        if (!projects || !consultants) {
            return initialEmptyArray ? [] : undefined;
        }
        return projects.map((p) => expandProject(p, consultants));
    }, [projects, consultants, initialEmptyArray]);
}
