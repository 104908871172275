import { COMPARATOR, DatePeriod, intersect, percentageFormatter } from '../../common';
import { LocalDate, Month, Period } from '@js-joda/core';
import { ColDef, ColGroupDef, ColSpanParams } from '@ag-grid-community/core';
import { ProjectExpanded } from '../../model/Project';
import { ActionButtons, LocationCell, TableCell } from './ProjectsOverview';
import { ConsultantExpanded } from '../../model/Consultant';
import { AssignmentExpanded } from '../../model/Assignment';
import { Location } from '../../model/Location';
import { AssignmentRow, ProjectRow, TableRow } from './ProjectsTableModel';
import './projects.scss';
import { ViewPeriods } from '../../common/ViewPeriod';
import { ProjectPotentialModel } from '../../common/ProjectPotentialModel';
import compact from 'lodash/compact';
import find from 'lodash/find';

export function monthsBetween(period: DatePeriod): number {
    return Period.between(period.start.with(ViewPeriods.monthly.startAdjuster), period.end).toTotalMonths() + 1; // last month inclusive
}

export function generateProjectsFilter(period: DatePeriod) {
    return function (row: TableRow): boolean {
        return intersect(row.getRenderPeriod(), period);
    };
}

export function appendAssignments(
    projects: TableRow[],
    assignmentExpanded: AssignmentExpanded[],
    periodToRender: DatePeriod,
): TableRow[] {
    const projectsAndAssignments: TableRow[] = [];
    for (const project of projects) {
        projectsAndAssignments.push(project);
        const matchingAssingments = assignmentExpanded.filter((assignment) => assignment.projectId === project.getId());
        const assigmentsToRender = matchingAssingments.filter((assignment) => {
            const assignemntPeriod = assignment.getEffectiveDuration();
            return intersect(assignemntPeriod, periodToRender);
        });
        const assignmentConverted: TableRow[] = assigmentsToRender.map((ass) => new AssignmentRow(ass));
        assignmentConverted.sort(compareRows);
        projectsAndAssignments.push(...assignmentConverted);
    }
    return projectsAndAssignments;
}

function insertMainLocation(project: ProjectExpanded, locations: Location[]): Location[] {
    const mainLocation = project.accountManager?.location;
    return mainLocation ? [mainLocation, ...locations.filter((l) => l.id !== mainLocation.id)] : locations;
}

export function getCreateButtonName(onlyProjects: boolean): string {
    return onlyProjects ? 'Neues Projekt' : 'Neuer Einsatz';
}

export function getCreateUrlPrefix(onlyProjects: boolean): string {
    return onlyProjects ? 'projects' : 'assignments';
}

export async function expandProjects(
    projects: ProjectExpanded[],
    assigmentsExpanded: AssignmentExpanded[],
    consultants: ConsultantExpanded[],
    interval: DatePeriod,
): Promise<TableRow[]> {
    const projectAndLocation: Map<string, Set<Location>> = new Map(); // projectId : Set<locationName> multiple consultants from multiple locations
    const potentialModel = new ProjectPotentialModel(assigmentsExpanded);
    assigmentsExpanded.forEach((assignment) => {
        const location = assignment?.consultant?.location;
        if (location) {
            const currentProject = projectAndLocation.get(assignment.projectId) || new Set<Location>([location]);
            currentProject.add(location);
            projectAndLocation.set(assignment.projectId, currentProject);
        }
    });
    return projects.map((project) => {
        const locations = projectAndLocation.get(project.id) || [];
        const projectAssignmentsWithinInterval = assigmentsExpanded.filter(
            (a) => a.project?.id === project.id && intersect(a.getEffectiveDuration(), interval),
        );
        const accountManager = find(consultants, { id: project.accountManagerId });
        const usage = potentialModel.calculateUsage(project, interval);
        return new ProjectRow(
            project,
            insertMainLocation(project, [...locations]),
            projectAssignmentsWithinInterval,
            accountManager,
            usage,
        );
    });
}

export function compareRows(rowA: TableRow, rowB: TableRow): number {
    const periodA = rowA.getRenderPeriod();
    const periodB = rowB.getRenderPeriod();
    if (periodA.start.compareTo(periodB.start) === 0) {
        return periodA.end.compareTo(periodB.end);
    } else {
        return periodA.start.compareTo(periodB.start);
    }
}

export function formatLocation(locations: Location[]): string {
    if (!locations) {
        return '';
    }
    const locationNames = locations.map((location) => location.name);
    return locationNames.join(', ');
}

export function createColumns(period: DatePeriod, onlyProjects: boolean): ColGroupDef[] {
    const projectColGroupDef: ColGroupDef = {
        headerName: 'Projekt',
        children: compact([
            { headerName: 'Name', field: 'name', width: 320, lockPosition: true, sortable: onlyProjects, comparator: COMPARATOR },
            {
                headerName: 'Wahrscheinlichkeit',
                field: 'probability',
                width: 100,
                filter: 'agNumberColumnFilter',
                valueFormatter: percentageFormatter,
                cellClass: 'text-right',
                lockPosition: true,
                sortable: onlyProjects,
            },
            onlyProjects && {
                headerName: '# 👤',
                headerTooltip: 'Anzahl Einsätze im ausgewählten Zeitraum',
                field: 'numberOfAssignments',
                width: 75,
                filter: 'agNumberColumnFilter',
                cellClass: 'text-right',
                lockPosition: true,
                sortable: true,
            },
            {
                headerName: 'Standorte',
                valueGetter: (params) => formatLocation(params.data.locations),
                cellRenderer: LocationCell,
                width: 120,
                lockPosition: true,
                sortable: onlyProjects,
                comparator: COMPARATOR,
                cellClass: function (params) {
                    const accountManagerLocation = params.data.project?.accountManager?.location;
                    return params.data.locations[0] === accountManagerLocation ? 'account-manager-location' : '';
                },
            },
            {
                headerName: 'Location group',
                hide: true,
                valueGetter: (params) => params.data.locations.map((l: Location) => l.region).join(' '),
            },
            onlyProjects && {
                headerName: 'Aktion',
                cellRenderer: ActionButtons,
                width: 165,
                lockPosition: true,
                filter: false,
                sortable: false,
            },
        ]),
    };

    const columns: ColGroupDef[] = [projectColGroupDef];

    for (let currentYear = period.start.year(); currentYear <= period.end.year(); currentYear++) {
        columns.push({
            headerName: currentYear.toString(),
            children: generateMonthColumns(period, currentYear),
        });
    }

    return columns;
}

function max(a: LocalDate, b: LocalDate): LocalDate {
    return a.isBefore(b) ? b : a;
}

function min(a: LocalDate, b: LocalDate): LocalDate {
    return a.isAfter(b) ? b : a;
}

export function shouldRenderCell(normalizedPeriod: DatePeriod, currentMonth: Month, currentYear: number): boolean {
    return normalizedPeriod.start.month().equals(currentMonth) && normalizedPeriod.start.year() === currentYear;
}

export function normalizePeriod(currentPeriod: DatePeriod, projectPeriod: DatePeriod): DatePeriod {
    return { start: max(currentPeriod.start, projectPeriod.start), end: min(currentPeriod.end, projectPeriod.end) };
}

// calculates the number of months starting from the relevant month, other months will get a 0
export function calculateCellLength(
    currentPeriod: DatePeriod,
    projectPeriod: DatePeriod,
    currentMonth: Month,
    currentYear: number,
): number {
    const normalizedProjectPeriod = normalizePeriod(currentPeriod, projectPeriod);
    return shouldRenderCell(normalizedProjectPeriod, currentMonth, currentYear) ? monthsBetween(normalizedProjectPeriod) : 0;
}

function generateCellMonth(currentPeriod: DatePeriod, currentMonth: Month, currentYear: number): ColDef {
    return {
        headerName: ViewPeriods['monthly'].headlineFormatter.format(currentMonth),
        colSpan: function (params: ColSpanParams) {
            const period = params.data.getRenderPeriod();
            return calculateCellLength(currentPeriod, period, currentMonth, currentYear);
        },
        width: 80,
        cellClass: 'project-util',
        cellRenderer: TableCell,
        sortable: false,
        lockPosition: true,
        cellRendererParams: { currentPeriod, currentMonth: currentMonth, currentYear },
        getQuickFilterText: (p) => p?.data?.quickFilterText,
        tooltipValueGetter: (p) => p?.data?.toolTipText,
    };
}

export function generateMonthColumns(periodToRender: DatePeriod, currentYear: number): ColDef[] {
    const monthColumns: ColDef[] = [];
    let currentDate = periodToRender.start.with(ViewPeriods.monthly.startAdjuster);
    do {
        if (currentDate.year() === currentYear) {
            const currentMonth = currentDate.month();
            monthColumns.push(generateCellMonth(periodToRender, currentMonth, currentYear));
        }
        currentDate = currentDate.plus(ViewPeriods.monthly.period);
    } while (!periodToRender.end.isBefore(currentDate));
    return monthColumns;
}
