import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../store';
import { AG_GRID_REACT_DEFAULT_PROPS, all2, COLUMN_DEFAULTS, getVisibleRows } from '../../common';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import './Workload.scss';
import { ConsultantExpanded } from '../../model/Consultant';
import { getConsultantsExpanded } from '../consultants/ConsultantsApi';
import { AssignmentExpanded } from '../../model/Assignment';
import { getAssignmentsWithConsultants } from '../assignments/AssignmentsApi';
import {
    exportCsv,
    generateColumnModel,
    generateModel,
    generateSummaryRowForRows,
    Row,
    SummaryRow,
    ViewDataType,
    WorkloadCellData,
} from './WorkloadUtils';
import { Location } from '../../model/Location';
import { sortByName } from '../../model/Selectable';
import { CellClassParams } from '@ag-grid-community/core';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import { PopoverTrigger } from '../common/PopoverTrigger';
import { Button } from 'react-bootstrap';
import { useCurrentLocations } from '../../customHooks/currentLocations';
import { useGlobalInterval } from '../../customHooks/globalInterval';
import { TableContentOptions } from '../common/TableContentOptions';

import { RootState } from '../../../store/setupStore';

interface Data {
    consultants: ConsultantExpanded[];
    assignments: AssignmentExpanded[];
}

const installGridStatePersister = generateGridStatePersistenceInstaller();

async function loadData(): Promise<Data> {
    const consultantsPromise = getConsultantsExpanded();
    const assignmentsPromise = getAssignmentsWithConsultants(consultantsPromise);

    const [assignments, consultants] = await all2(assignmentsPromise, consultantsPromise);

    return { assignments, consultants };
}

export function Workload(): JSX.Element {
    const agGrid = useRef<AgGridReact>(null);

    const [interval] = useGlobalInterval();

    const currentLocations = useCurrentLocations();

    const [data, setData] = useState<Data>();
    useEffect(() => {
        loadData().then((res) => setData(res));
    }, [setData]);

    const type = useAppSelector((state: RootState) => state.periodSelector.selectedPeriod);

    const model = useMemo(() => {
        if (data) {
            return generateModel(interval, type, sortByName(currentLocations), data.consultants, data.assignments);
        }
    }, [data, type, interval, currentLocations]);

    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);
    const [summary, setSummary] = useState<SummaryRow[]>();

    useEffect(() => {
        // useMemo doesnt work here
        if (model) {
            const visibleRows = getVisibleRows<Row>(agGrid.current);
            setSummary([generateSummaryRowForRows(visibleRows, model.buckets)]);
        }
    }, [model, quickFilterText]);

    const colDefs = useMemo(() => {
        return model ? generateColumnModel(model.buckets, type) : [];
    }, [model, type]);

    return (
        <div className="grid-wrapper workload">
            <TableContentOptions
                globalIntervalSelector
                viewPeriodSelector
                quickFilterField
                exportButton={
                    <Button
                        className="btn btn-sm ms-1"
                        onClick={() => exportCsv(model?.rows || [], colDefs, summary || [])}
                        title="Auslastungen als CSV-Datei exportieren"
                    >
                        <span className="oi oi-external-link" />
                    </Button>
                }
            />
            <div className="grid-body ag-theme-balham">
                <AgGridReact
                    {...AG_GRID_REACT_DEFAULT_PROPS}
                    ref={agGrid}
                    defaultColDef={COLUMN_DEFAULTS}
                    columnDefs={colDefs}
                    rowData={model?.rows}
                    modules={[ClientSideRowModelModule]}
                    rowHeight={144}
                    quickFilterText={quickFilterText}
                    pinnedBottomRowData={summary}
                    components={{
                        location: LocationCell,
                        status: StatusCell,
                        workload: WorkloadCell,
                        total: TotalCell,
                        region: Region,
                    }}
                    onGridReady={installGridStatePersister}
                />
            </div>
        </div>
    );
}

function Region(props: CellClassParams): JSX.Element {
    return props.value ? <div className="text-vertical">{props.value}</div> : <></>;
}

function LocationCell(props: CellClassParams): JSX.Element {
    const location: Location = props.value;

    return <div className="wl-location">{location.name}</div>;
}

function TotalCell(): JSX.Element {
    return <div className="wl-location">Total</div>;
}

function StatusCell(): JSX.Element {
    return (
        <div className="wl-cell">
            <PopoverTrigger
                popover={InfoPopover(
                    'Consultants assigned to safe projects with a combined assignment and project probability of 100%',
                )}
            >
                <div className="wl-row">Contracted</div>
            </PopoverTrigger>
            <PopoverTrigger
                popover={InfoPopover(
                    'Consultants assigned to verbally contracted projects with a combined assignment and project probability of 90%-99%',
                )}
            >
                <div className="wl-row">Verb. Contracted</div>
            </PopoverTrigger>
            <PopoverTrigger popover={InfoPopover('Consultants assigned to absence projects')}>
                <div className="wl-row">Absent</div>
            </PopoverTrigger>
            <PopoverTrigger
                popover={InfoPopover(
                    'Remaining consultants that are assigned to projects with a combined assignment and project probability below 90%, or not assigned at all',
                )}
            >
                <div className="wl-row wl-open">Open</div>
            </PopoverTrigger>
            <div className="wl-row">
                <PopoverTrigger
                    inline
                    popover={InfoPopover(
                        'Consultants assigned to potential projects with a combined assignment and project probability of 50% - 89%',
                    )}
                >
                    <span>Pot. Contracted</span>
                    &nbsp;|&nbsp;
                </PopoverTrigger>
                <PopoverTrigger inline popover={InfoPopover('Total number of consultants at location')}>
                    <span>Total</span>
                </PopoverTrigger>
            </div>
            <PopoverTrigger
                popover={InfoPopover(
                    'Consultants that have yet to be assigned to complete the potential of projects. Only applies to projects based in this location and having a probability equal to or above 90%.',
                )}
            >
                <div className="wl-row">Open Potential</div>
            </PopoverTrigger>
        </div>
    );
}

function formatNumber(value: number, percent: boolean): string {
    const suffix = percent ? '%' : '';
    return isNaN(value) ? '-' : value.toFixed(1) + suffix;
}

function WorkloadCell(props: { type: ViewDataType } & CellClassParams): JSX.Element | null {
    const value: WorkloadCellData = props.value;
    const type: ViewDataType = props.type;

    if (!value) return null;

    const isPercentage = type === 'relative';
    const colorClass = type === 'relative' ? backgroundColor(value.data.open.relative) : '';
    const absentCount = type === 'absolute' ? value.absentConsultants.toFixed(1) : '';
    const totalCount =
        type === 'absolute'
            ? value.totalConsultants.toFixed(1)
            : `(${formatNumber(value.data.potentiallyContracted.relative, true)})`;
    const openPotential = isPercentage ? value.relativeLocationProjectPotential : value.locationProjectPotential;
    return (
        <div className="wl-cell">
            <div className="wl-row">{formatNumber(value.data.contracted[type], isPercentage)}</div>
            <div className="wl-row">{formatNumber(value.data.verballyContracted[type], isPercentage)}</div>
            <div className="wl-row">{absentCount}</div>
            <div className={'wl-row wl-open ' + colorClass}>{formatNumber(value.data.open[type], isPercentage)}</div>
            <PopoverTrigger enabled={type == 'relative'} popover={<WorkloadCellPopup {...value} />}>
                <div className="wl-row">{totalCount}</div>
            </PopoverTrigger>
            <div className="wl-row">{formatNumber(openPotential, isPercentage)}</div>
        </div>
    );
}

function backgroundColor(value: number): string {
    if (value < 0) {
        return 'state-yellow';
    } else if (value >= 0 && value < 10) {
        return 'state-green';
    } else if (value >= 10 && value < 20) {
        return 'state-yellow';
    } else {
        return 'state-red';
    }
}

function WorkloadCellPopup(value: WorkloadCellData): JSX.Element {
    const tooltip = '# 👤: ' + value.data.potentiallyContracted.absolute.toFixed(1);

    return <div style={{ color: '#777', padding: '4px' }}>{tooltip}</div>;
}

function InfoPopover(text: string): JSX.Element {
    return <div style={{ color: '#777', padding: '4px' }}>{text}</div>;
}
