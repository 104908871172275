/**
 * TODO:
 * - Migrate the locationsSlice (fetching and storing) to a RTK Query API
 *
 * Data flow for allPossibleLocations:
 * 1.1 store/allPossibleLocations is initialized as an empty list
 * 1.2 A reducer setAllPossibleLocations is defined to update allPossibleLocations
 * 2.1 applicationLifecycleSaga fetches all locations via LocationsApi/getLocations
 * 2.2 applicationLifecycleSaga calls setAllPossibleLocations with the fetched locations
 */
import { createApi } from '@reduxjs/toolkit/query/react';
import { Location } from '../../app/model/Location';
import { baseQuery } from './baseQuery';

const generateLocationTag = (location: Location) => ({ type: 'Location' as const, id: location.id });

export const locations = createApi({
    reducerPath: 'locationsApi',
    tagTypes: ['Location'],
    baseQuery,
    keepUnusedDataFor: 3600, // 1 hour
    endpoints: (builder) => ({
        getLocations: builder.query<Location[], void>({
            query: () => 'locations/',
            providesTags: (locations) => [
                {
                    type: 'Location',
                    id: 'LIST',
                },
                ...(locations?.map(generateLocationTag) || []),
            ],
        }),
        getLocation: builder.query<Location, string>({
            query: (id) => `locations/${id}`,
            providesTags: (result, error, id) => [{ type: 'Location', id }],
        }),
        createLocation: builder.mutation<Location, Location>({
            query: (location) => ({
                url: 'locations/',
                method: 'POST',
                body: location,
            }),
            invalidatesTags: [{ type: 'Location', id: 'LIST' }],
        }),
        updateLocation: builder.mutation<Location, Location>({
            query: (location) => ({
                url: `locations/${location.id}`,
                method: 'PUT',
                body: location,
            }),
            invalidatesTags: (result, error, location) => [generateLocationTag(location)],
        }),
        deleteLocation: builder.mutation<void, Location>({
            query: (location) => ({
                url: `locations/${location.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, location) => [generateLocationTag(location)],
        }),
    }),
});

export const {
    useGetLocationsQuery,
    useGetLocationQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation,
    useDeleteLocationMutation,
} = locations;
