import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { LocationGroup } from '../../app/model/LocationGroup';

const generateLocationGroupTag = (locationGroup: LocationGroup) => ({ type: 'LocationGroup' as const, id: locationGroup.id });

export const locationGroupsApi = createApi({
    reducerPath: 'locationGroupsApi',
    tagTypes: ['LocationGroup'],
    baseQuery,
    endpoints: (builder) => ({
        getGroups: builder.query<LocationGroup[], void>({
            query: () => 'locations/groups/',
            providesTags: (locationGroups) => [
                { type: 'LocationGroup', id: 'LIST' },
                ...(locationGroups?.map(generateLocationGroupTag) || []),
            ],
        }),
    }),
});

export const { useGetGroupsQuery } = locationGroupsApi;
