import { Assignment, AssignmentExpanded, AssignmentExpandedImpl, AssignmentImpl, SimpleAssignment } from '../../model/Assignment';
import { getConsultantsExpanded } from '../consultants/ConsultantsApi';
import { getProjectsWithConsultants } from '../projects/ProjectsApi';
import { ConsultantExpanded } from '../../model/Consultant';
import { ProjectExpanded } from '../../model/Project';
import { addIntervalToParams, DatePeriod, toQueryString } from '../../common';
import { send } from '../../common/send';
import { CONFIG } from '../../../config';

async function getSimpleAssignments(interval?: DatePeriod): Promise<SimpleAssignment[]> {
    const args = toQueryString(addIntervalToParams(new URLSearchParams(), interval));
    return send('GET', `${CONFIG.apiBaseUrl}/assignments/${args}`);
}

export async function getAssignments(interval?: DatePeriod): Promise<AssignmentExpanded[]> {
    const consultantsPromise = getConsultantsExpanded();
    return getAssignmentsWithConsultants(consultantsPromise, interval);
}

export async function getAssignmentsWithConsultants(
    consultantsPromise: Promise<ConsultantExpanded[]>,
    interval?: DatePeriod,
): Promise<AssignmentExpanded[]> {
    const assignmentsPromise = getSimpleAssignments(interval);
    const projectsPromise = getProjectsWithConsultants(consultantsPromise);

    const [assignments, consultants, projects] = await Promise.all([assignmentsPromise, consultantsPromise, projectsPromise]);
    return assignments.map((a) => expandAssignment(a, consultants, projects));
}

export function expandAssignment(
    assignment: SimpleAssignment,
    consultants: ConsultantExpanded[],
    projects: ProjectExpanded[],
): AssignmentExpanded {
    const consultant = consultants.find((c) => c.id === assignment.consultantId);
    if (!consultant) {
        throw `unable to resolve assignment ${assignment.id}: cannot find consultant ${assignment.consultantId}`;
    }

    const project = projects.find((p) => p.id === assignment.projectId);
    if (!project) {
        throw `unable to resolve assignment ${assignment.id}: cannot find project ${assignment.projectId}`;
    }

    return new AssignmentExpandedImpl(assignment, consultant, project);
}

export async function getAssignment(id: string): Promise<Assignment> {
    return new AssignmentImpl(await send('GET', `${CONFIG.apiBaseUrl}/assignments/${id}`));
}

export async function createAssignment(assignment: Assignment): Promise<Assignment> {
    return new AssignmentImpl(await send('POST', `${CONFIG.apiBaseUrl}/assignments/`, assignment));
}

export async function updateAssignment(assignment: Assignment): Promise<Assignment> {
    return new AssignmentImpl(await send('PUT', `${CONFIG.apiBaseUrl}/assignments/${assignment.id}`, assignment));
}

export async function deleteAssignment(assignment: Assignment): Promise<void> {
    return send('DELETE', `${CONFIG.apiBaseUrl}/assignments/${assignment.id}`);
}
