import React, { useCallback } from 'react';

import Form from 'react-bootstrap/Form';
import { useField } from 'formik';

const NUMBER_PATTERN = /^[0-9.,]/;

export function FormField({ label, controlId, lateAutoFocus, hint, controlRef, ...props }: any): JSX.Element {
    const [field, meta] = useField(props);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { validate, ...remainingProps } = props;

    const delayedSetFocus = useCallback((inputElement: any) => {
        if (lateAutoFocus && inputElement) {
            setTimeout(() => inputElement.focus(), 100);
        }
        if (controlRef && inputElement) {
            controlRef.current = inputElement;
        }
    }, []);

    const preventNonNumericalCharacters = useCallback(
        (e: KeyboardEvent) => {
            if (props.type === 'number' && e.key && !NUMBER_PATTERN.test(e.key)) {
                e.preventDefault();
            }
        },
        [props],
    );

    return (
        <Form.Group controlId={controlId ? controlId : 'form_' + props.name} className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control
                isInvalid={meta.touched && meta.error}
                {...field}
                {...remainingProps}
                ref={delayedSetFocus}
                onKeyPress={preventNonNumericalCharacters}
            />
            {meta.touched && meta.error ? <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback> : null}
            {hint ? <Form.Text className="text-muted">{hint}</Form.Text> : null}
        </Form.Group>
    );
}
