import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';

import { FormField } from '../common/FormField';
import { isSubmitDisabled, validateNonEmptyString } from '../common/form';

import { Project } from '../../model/Project';
import { deleteProject } from './ProjectsApi';
import { AssignmentExpanded, AssignmentPredicate } from '../../model/Assignment';
import { getAssignments } from '../assignments/AssignmentsApi';
import { AssignmentShortList } from '../assignments/AssignmentShortList';

interface ArchiveProjectModalProps {
    deleteProject: Project;
    setDeleteProject: (value: Project | null) => void;
}

interface ModalForm {
    reason: string;
}

function assignmentsForProject(project: Project): AssignmentPredicate {
    return (assignment) => assignment.projectId === project.id;
}

export function ArchiveProjectModal(props: ArchiveProjectModalProps): JSX.Element {
    const [show, setShow] = useState(false);
    const [assignments, setAssignments] = useState<AssignmentExpanded[]>();

    useEffect(() => {
        setShow(!!props.deleteProject);
        if (props.deleteProject) {
            getAssignments().then((allAssignments) => {
                setAssignments(allAssignments.filter(assignmentsForProject(props.deleteProject)));
            });
        }
    }, [props.deleteProject]);

    const handleClose = () => {
        props.setDeleteProject(null);
    };

    const onSubmit = async (values: ModalForm) => {
        try {
            await deleteProject(props.deleteProject, values.reason);
            props.setDeleteProject(null);
        } catch (error) {
            console.error('error while deleting project', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Formik initialValues={{ reason: '' }} onSubmit={onSubmit}>
                {(formProps) => (
                    <Form onSubmit={formProps.handleSubmit} noValidate>
                        <Modal.Header closeButton>
                            <Modal.Title>Projekt archivieren</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Soll das Projekt &quot;{props.deleteProject.name}&quot; wirklich archiviert werden?
                            <FormField
                                name="reason"
                                as="textarea"
                                placeholder="Begründung"
                                lateAutoFocus
                                validate={validateNonEmptyString('Please enter a reason for archival')}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div>Archivierte Projekte können nicht wiederhergestellt werden</div>
                            <div>
                                <Button variant="secondary" className="me-2" onClick={handleClose}>
                                    Cancel
                                </Button>

                                <Button variant="primary" type="submit" disabled={isSubmitDisabled(formProps)} className="me-2">
                                    Archive Project
                                </Button>
                            </div>
                            <AssignmentShortList
                                assignments={assignments}
                                header={<h6>Einsätze zum Projekt (werden gelöscht):</h6>}
                                noAssignments={<h6>Keine Einsätze zum Projekt</h6>}
                                assignmentMapper={(assignment: AssignmentExpanded) => assignment.consultant.name}
                            />
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
