import { send } from '../../common/send';
import { Location } from '../../model/Location';
import { AssignmentExpanded } from '../../model/Assignment';
import { ConsultantExpanded } from '../../model/Consultant';
import { getAssignmentsWithConsultants } from '../assignments/AssignmentsApi';
import { getConsultantsExpanded } from '../consultants/ConsultantsApi';
import { CONFIG } from '../../../config';
import { WarningByLocationModel } from './warnings/WarningByLocationModel';
import { all3, IsoDateString } from '../../common';
import { getRedirectUrl } from '../navigation/MainRouter';
import { DateTimeFormatter, LocalDate, TemporalAmount } from '@js-joda/core';
import { Locale } from '@js-joda/locale_de-de';
import { AnyAction } from 'redux';
import { getLastChangesCount } from '../changes/ChangesApi';
import { setQuickFilter } from '../../../store/slices/filter';
import { getProjects } from '../projects/ProjectsApi';
import { ProjectExpanded } from '../../model/Project';

export type WarningItem = {
    text: string;
    href: string;
    action?: AnyAction;
};

export type WarningData = {
    consultants: ConsultantExpanded[];
    assignments: AssignmentExpanded[];
    locations: Location[];
    warnings: ApiWarning[];
    projects: ProjectExpanded[];
};

// API model
type ApiWarning = {
    messageArgs: string[];
    messageKey: WARNING_KEY;
    referenceId: string;
    referenceType: REFERENCE_TYPES;
};

export enum REFERENCE_TYPES {
    PROJECT = 'project',
    ASSIGNMENT = 'assignment',
    CONSULTANT = 'consultant',
}

// TODO for i18n Keys and values in a separate file with all supported languages
// FROM BACKEND
// warning.project.invalid.date=Ungültiges Projekt, Start liegt nach Ende.
// warning.project.noone=Es ist noch niemand für Projekt {0} geplant. G
// warning.assignment.invalid.date=Ungültiger Einsatz, Start liegt nach Ende.
// warning.assignment.invalid.project=Ungültiger Einsatz, außerhalb Projektlaufzeit.
// warning.assignment.invalid.consultant=Ungültiger Einsatz, außerhalb Consultantverfügbarkeit.
// warning.consultant.overbooked={0} ist zu {1}% verplant.
// warning.consultant.nolocation=Consultant ohne zugeordneten Standort. G

export enum WARNING_KEY {
    INVALID_DATE_PROJECT = 'warning.project.invalid.date',
    NO_ONE_ASSIGNED = 'warning.project.noone',
    DUPLICATE_NAME = 'warning.project.duplicate.name',
    INVALID_DATE_ASSIGNMENT = 'warning.assignment.invalid.date',
    INVALID_ASSIGNMENT = 'warning.assignment.invalid.project',
    INVALID_CONSULTANT = 'warning.assignment.invalid.consultant',
    OVERBOOKED = 'warning.consultant.overbooked',
    NO_LOCATION = 'warning.consultant.nolocation',
}

const generalLocation = { name: 'Allgemein', id: 'general' };

async function getWarnings(): Promise<ApiWarning[]> {
    return send('GET', `${CONFIG.apiBaseUrl}/warnings/`);
}

export async function getWarningsData(locations: Location[]): Promise<WarningData> {
    const consultantsPromise = getConsultantsExpanded();
    const [assignments, warnings, projects] = await all3(
        getAssignmentsWithConsultants(consultantsPromise),
        getWarnings(),
        getProjects(),
    );
    const consultants = await consultantsPromise;
    return { consultants, assignments, locations, warnings, projects };
}

export function generateWarningByLocationModel(warningData: WarningData, currentLocations: Location[]): WarningByLocationModel {
    const warningByLocationModel = new WarningByLocationModel(currentLocations.concat(generalLocation));
    warningData.warnings.forEach((warning: ApiWarning) => {
        const warningItem = warningToMessageItem(warning);
        const locations = warningToLocations(warning, warningData);
        locations.forEach((location) => {
            if (isLocationRelevant(location, currentLocations)) {
                warningByLocationModel.addWarningToLocation(location, warningItem);
            }
        });
    });
    return warningByLocationModel;
}

export function sortLocationAlphabetically(a: Location, b: Location): number {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
}

function isLocationRelevant(location: Location, currentLocations: Location[]): boolean {
    return !!currentLocations.find((currentLocation) => currentLocation.id === location.id) || location.id === 'general';
}

function warningToLocations(warning: ApiWarning, data: WarningData): Location[] {
    switch (warning.referenceType) {
        case REFERENCE_TYPES.PROJECT:
            return getLocationsForProject(warning.referenceId, data.assignments, data.projects);
        case REFERENCE_TYPES.ASSIGNMENT:
            return [getLocationForAssignment(warning.referenceId, data.assignments)];
        case REFERENCE_TYPES.CONSULTANT:
            return [getLocationForConsultant(warning.referenceId, data.consultants)];
        default:
            throw 'Invalid reference type';
    }
}

function warningToMessageItem(warning: ApiWarning): WarningItem {
    const text = getTextFromKey(warning.messageKey, warning.messageArgs);
    let href = getRedirectUrl(warning.referenceType, warning.referenceId);
    let action = undefined;

    // special target for OVERBOOKED warnings
    if (warning.messageKey === WARNING_KEY.OVERBOOKED) {
        href = 'assignments';
        if (warning.messageArgs && warning.messageArgs[0]) {
            action = setQuickFilter({ query: warning.messageArgs[0] });
        }
    }

    return { text, href, action };
}

function getTextFromKey(messageKey: string, args: string[]): string {
    // provide default value if args are not sufficient
    const arg = (index: number): string => (args && args[index] ? args[index] : 'null');
    const argAsMonth = (index: number): string => (args && args[index] ? formatMonth(args[index]) : 'null');

    switch (messageKey) {
        case WARNING_KEY.INVALID_DATE_PROJECT:
            return 'Ungültiges Projekt, Start liegt nach Ende.';
        case WARNING_KEY.NO_ONE_ASSIGNED:
            return `Es ist noch niemand für Projekt ${arg(0)} geplant.`;
        case WARNING_KEY.DUPLICATE_NAME:
            return `Das Projekt ${arg(0)} ist mehrfach vorhanden.`;
        case WARNING_KEY.INVALID_DATE_ASSIGNMENT:
            return `Ungültiger Einsatz, Start liegt nach Ende: ${arg(0)}`;
        case WARNING_KEY.INVALID_ASSIGNMENT:
            return `Ungültiger Einsatz, außerhalb Projektlaufzeit: ${arg(0)}`;
        case WARNING_KEY.INVALID_CONSULTANT:
            return `Ungültiger Einsatz, außerhalb Consultantverfügbarkeit: ${arg(0)}`;
        case WARNING_KEY.OVERBOOKED:
            return `${arg(0)} ist im ${argAsMonth(2)} zu ${arg(1)}% verplant.`;
        case WARNING_KEY.NO_LOCATION:
            return 'Consultant ohne zugeordneten Standort.';
        default:
            return 'Server data error';
    }
}

function formatMonth(date: IsoDateString): string {
    const parsedDate = LocalDate.parse(date);
    const formatter = DateTimeFormatter.ofPattern('MMMM').withLocale(Locale.GERMANY);
    return parsedDate.format(formatter);
}

// One project can have multiple locations, this is based on the assignments.
function getLocationsForProject(projectId: string, assignments: AssignmentExpanded[], projects: ProjectExpanded[]): Location[] {
    const locationSet = new Set<Location>();

    // location of account manager
    projects
        .filter((project) => project.id === projectId)
        .forEach((project) => {
            if (project.accountManager && project.accountManager.location) {
                locationSet.add(project.accountManager.location);
            }
        });

    // locations of all assigned consultants
    const relevantAssignments = assignments.filter((assignment) => assignment.projectId === projectId);
    relevantAssignments.forEach((relevantAssignment) => {
        const consultantExpanded = relevantAssignment.consultant;
        if (consultantExpanded && consultantExpanded.location) {
            locationSet.add(consultantExpanded.location);
        }
    });
    if (locationSet.size === 0) return [generalLocation];
    return [...locationSet];
}

function getLocationForAssignment(assinmentId: string, allAssignments: AssignmentExpanded[]): Location {
    const assignment = allAssignments.find((assignmentToFind) => assignmentToFind.id === assinmentId);
    if (!assignment) {
        throw `Assignment with id : ${assinmentId} was not found`;
    }
    const consultant = assignment.consultant;
    const location = consultant.location;
    if (!location) {
        throw `Consultant ${consultant.name} doesnt have a location`;
    }
    return location;
}

function getLocationForConsultant(consultantId: string, consultants: ConsultantExpanded[]): Location {
    const consultant = consultants.find((consultant) => consultant.id === consultantId);
    if (!consultant) {
        throw `Consultant with id ${consultantId} was not found`;
    }
    const location = consultant.location;
    if (!location) {
        throw `Consultant ${consultant.name} doesnt have a location`;
    }
    return location;
}

const CHANGES_PATH = 'administrative/changelog';

export type ChangesPreviewEntry = {
    href: string;
    changesAmount?: number;
};

export async function getChangesPreviewEntryForPastPeriod(
    pastPeriod: TemporalAmount,
    currentLocationIds?: string[],
): Promise<ChangesPreviewEntry> {
    const period = { start: LocalDate.now().minus(pastPeriod), end: LocalDate.now() };
    const changesAmount = await getLastChangesCount(currentLocationIds, period);
    const href = getChangesPreviewHref(pastPeriod);
    return {
        changesAmount,
        href,
    };
}

export function getChangesPreviewHref(pastPeriod: TemporalAmount): string {
    const period = { start: LocalDate.now().minus(pastPeriod), end: LocalDate.now() };
    return `/${CHANGES_PATH}?start=${period.start.toString()}&end=${period.end.toString()}`;
}
