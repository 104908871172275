import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { Consultant } from '../../app/model/Consultant';
import { Tag } from './types';

const CONSULTANT_TAG_TYPE = 'Consultant' as const;
const CONSULTANT_LIST_TAG = {
    type: CONSULTANT_TAG_TYPE,
    id: 'LIST',
};

const generateConsultantTag = (consultant: Consultant): Tag<typeof CONSULTANT_TAG_TYPE> => ({
    type: CONSULTANT_TAG_TYPE,
    id: consultant.id,
});

// TODO: Remove manual refetches once mutations are migrated to RTK
export const consultantsApi = createApi({
    reducerPath: 'consultantsApi',
    tagTypes: [CONSULTANT_TAG_TYPE],
    baseQuery,
    keepUnusedDataFor: 10,
    endpoints: (builder) => ({
        getConsultants: builder.query<Consultant[], void>({
            query: () => 'consultants/',
            providesTags: (consultants) => [CONSULTANT_LIST_TAG, ...(consultants?.map(generateConsultantTag) || [])],
        }),
        getConsultant: builder.query<Consultant, string>({
            query: (id) => `consultants/${id}`,
            providesTags: (result, error, id) => [{ type: CONSULTANT_TAG_TYPE, id }],
        }),
        createConsultant: builder.mutation<Consultant, Consultant>({
            query: (consultant) => ({
                url: 'consultants/',
                method: 'POST',
                body: JSON.stringify(consultant),
            }),
            invalidatesTags: [CONSULTANT_LIST_TAG],
        }),
        updateConsultant: builder.mutation<Consultant, Consultant>({
            query: (consultant) => ({
                url: `consultants/${consultant.id}`,
                method: 'PUT',
                body: JSON.stringify(consultant),
            }),
            invalidatesTags: (result, error, consultant) => [CONSULTANT_LIST_TAG, generateConsultantTag(consultant)],
        }),
        deleteConsultant: builder.mutation<void, Consultant>({
            query: (consultant) => ({
                method: 'DELETE',
                url: `consultants/${consultant.id}`,
            }),
            invalidatesTags: (result, error, consultant) => [CONSULTANT_LIST_TAG, generateConsultantTag(consultant)],
        }),
    }),
});

export const {
    useGetConsultantsQuery,
    useGetConsultantQuery,
    useCreateConsultantMutation,
    useUpdateConsultantMutation,
    useDeleteConsultantMutation,
} = consultantsApi;
