import { ColDef, SortDirection, ValueFormatterParams } from '@ag-grid-community/core';
import { convert, DateTimeFormatter, DayOfWeek, LocalDate, ZonedDateTime, ZoneId } from '@js-joda/core';
import { CONFIG } from '../config';
import { Locale } from '@js-joda/locale_de-de';
import { AgGridReact as AgGridReactRef, AgGridReactProps } from '@ag-grid-community/react';
import { Location } from './model/Location';
import _ from 'lodash';
import { Consultant, ConsultantExpanded } from './model/Consultant';
import { LoadingOverlay } from './components/common/LoadingOverlay';
import { Project } from './model/Project';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { useSearchParams } from 'react-router-dom';

export type IsoDateString = string;
export type IsoDateTimeString = string;

export const DATE_PATTERN = DateTimeFormatter.ofPattern('dd.MM.yyyy').withLocale(Locale.GERMAN);
export const DATE_TIME_PATTERN = DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm').withLocale(Locale.GERMAN);
export const DATE_TIME_FILENAME_PATTERN = DateTimeFormatter.ofPattern('yyyy-MM-dd_HH-mm').withLocale(Locale.GERMAN);

export const REACT_SELECT_STYLES_SMALL: Partial<StylesConfig<any, boolean>> = {
    control: (base) => {
        return { ...base, flexWrap: 'nowrap', minHeight: '32px', height: '32px', maxHeight: '32px' };
    },
    valueContainer: (base) => {
        return { ...base, flexWrap: 'nowrap' };
    },
    multiValue: (base) => {
        return { ...base, flexShrink: 0, height: '26px', marginBottom: '3px', marginTop: '3px' };
    },
    multiValueLabel: (base, state: { data: { value: string } }) => {
        return state.data.value === '*' ? { ...base, paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state: { data: { value: string } }) => {
        return state.data.value === '*' ? { ...base, display: 'none' } : base;
    },
};

export const COMPARATOR = new Intl.Collator('de').compare;
export const arrayComparator = (valueA: any, valueB: any) => {
    if (valueA.length === valueB.length) {
        const a = valueA.join('');
        const b = valueB.join('');
        return b.localeCompare(a);
    }
    return valueA.length > valueB.length ? 1 : -1;
};

export const COLUMN_DEFAULTS: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
};

export const DATE_FILTER_PARAMS = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue?: IsoDateString) => {
        if (cellValue) {
            const cellDate = convert(parseIsoDateString(cellValue)).toDate();
            return cellDate.getTime() - filterLocalDateAtMidnight.getTime();
        }
    },
    newRowsAction: 'keep',
};

export const AG_GRID_REACT_DEFAULT_PROPS: AgGridReactProps = {
    enableBrowserTooltips: false,
    tooltipShowDelay: 500,
    loadingOverlayComponent: LoadingOverlay,
    columnMenu: 'legacy',
};

export const COMPACT_ROW_HEIGHT = 30;

export const FIRST_CLICK_SORTS_DESCENDING: SortDirection[] = [null, 'desc', 'asc'];

export function percentageFormatter(params: ValueFormatterParams): string {
    return params?.value != null ? `${Math.round(params.value)}%` : '';
}

export function hoursFormatter(params: ValueFormatterParams): string {
    return params?.value != null ? `${params.value}h` : '';
}

export function isoDateCellFormatter(params: ValueFormatterParams): string {
    return params?.value ? formatIsoDateString(params.value) : '';
}

export function isoDateTimeCellFormatter(params: ValueFormatterParams): string {
    return params?.value ? formatIsoDateTimeString(params.value) : '';
}

export function formatIsoDateString(dateString: IsoDateString | undefined | null): string {
    if (dateString) {
        try {
            return formatLocalDate(parseIsoDateString(dateString));
        } catch (e) {
            console.warn(e);
        }
    }
    return '';
}

export function formatIsoDateTimeString(dateTimeString: IsoDateTimeString | undefined | null): string {
    if (dateTimeString) {
        try {
            return formatZonedDateTime(ZonedDateTime.parse(dateTimeString).withZoneSameInstant(ZoneId.SYSTEM));
        } catch (e) {
            console.warn(e);
        }
    }
    return '';
}

export function formatLocalDate(date: LocalDate | undefined | null): string {
    return date ? date.format(DATE_PATTERN) : '';
}

export function formatZonedDateTime(dateTime: ZonedDateTime | undefined | null): string {
    return dateTime ? dateTime.format(DATE_TIME_PATTERN) : '';
}

export function earlierDate(a: LocalDate | undefined, b: LocalDate | undefined): LocalDate | undefined {
    // undefined means here: until the end of time
    if (!a) return b;
    if (!b) return a;

    return a.isBefore(b) ? a : b;
}

export function laterDate(a: LocalDate | undefined, b: LocalDate | undefined): LocalDate | undefined {
    // undefined means here: since beginning of time
    if (!a) return b;
    if (!b) return a;

    return a.isAfter(b) ? a : b;
}

export function intersect(a: Partial<DatePeriod>, b: Partial<DatePeriod>): boolean {
    const start = laterDate(a.start, b.start);
    if (!start) return true;

    const end = earlierDate(a.end, b.end);
    if (!end) return true;

    return !start.isAfter(end);
}

// TODO: rename to isDayInInterval
export function isDayInPeriod(day: LocalDate, datePeriod: DatePeriod): boolean {
    const { start, end } = datePeriod;
    return !day.isBefore(start) && !day.isAfter(end);
}

/**
 * Returns true if at least one element in a is in b. Used for filtering purposes
 * @param a
 * @param b
 */
export function intersectLocation(a: Location[], b: Location[]): boolean {
    return _.intersectionBy(a, b, 'id').length > 0;
}

/**
 * Returns the intersection of the two passed intervals. If there is no intersection of the given intervals,
 * an interval will be returned with start > end date.
 *
 * @param a is expected to be bounded
 * @param b might be unbounded (i.e. have undefined start and/or end)
 */
export function intersection(a: DatePeriod, b: Partial<DatePeriod>): DatePeriod {
    // Since a is required to be bounded, we know result has to be bounded, too
    const start = laterDate(a.start, b.start) as LocalDate;
    const end = earlierDate(a.end, b.end) as LocalDate;

    return { start, end };
}

export function booleanFormatter(params: ValueFormatterParams): string {
    if (params?.value != null) {
        return params?.value ? 'ja' : 'nein';
    }

    return '';
}

export function parseOptionalIsoDateString(value: IsoDateString | null | undefined): LocalDate | null {
    if (!value) {
        return null;
    }

    return parseIsoDateString(value);
}

const ISO_DATE_PATTERN = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;

export function parseIsoDateString(value: IsoDateString): LocalDate {
    const match = ISO_DATE_PATTERN.exec(value);
    if (match) {
        return LocalDate.of(parseInt(match[1]), parseInt(match[2]), parseInt(match[3]));
    }

    throw 'unable to parse date string: ' + value;
}

export function useQuery(): URLSearchParams {
    const [searchParams] = useSearchParams();
    return searchParams;
}

export function mergeSearchParams(searchParams1: URLSearchParams, searchParams2: Record<string, string>): URLSearchParams {
    const result = new URLSearchParams();
    for (const [key, value] of searchParams1.entries()) {
        result.set(key, value);
    }
    Object.entries(searchParams2).forEach(([key, value]) => result.set(key, value));
    return result;
}

/** Returns true, if the given day does not lie on a weekend. */
export function isWorkday(day: LocalDate): boolean {
    const dayOfWeek = day.dayOfWeek();
    return dayOfWeek !== DayOfWeek.SATURDAY && dayOfWeek !== DayOfWeek.SUNDAY;
}

/** Defines a period of time between two dates, the end date being inclusive.  */
// TODO: should be renamed to interval, in accordance to joda
export interface DatePeriod {
    start: LocalDate;
    end: LocalDate;
}

/** Returns the days of the given period, optionally filtered by the given predicate. */
export function* daysOfPeriod(period: DatePeriod, predicate?: Predicate<LocalDate>): IterableIterator<LocalDate> {
    let current = period.start;
    while (!current.isAfter(period.end)) {
        if (!predicate || predicate(current)) {
            yield current;
        }
        current = current.plusDays(1);
    }
}

/** Returns the number of workdays in the given interval. */
export function countWorkdays(interval: DatePeriod): number {
    let count = 0;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const day of daysOfPeriod(interval, isWorkday)) {
        count++;
    }

    return count;
}

export function addIntervalToParams(params: URLSearchParams, interval?: DatePeriod): URLSearchParams {
    if (interval && interval.start) {
        params.set('from', interval.start.toString());
    }

    if (interval && interval.end) {
        params.set('to', interval.end.toString());
    }

    return params;
}

export function toQueryString(query: URLSearchParams): string {
    const queryStr = query.toString();
    return queryStr ? '?' + queryStr : '';
}

export function getVisibleRows<T>(agGrid: AgGridReactRef | null): T[] {
    const visibleRows: T[] = [];
    agGrid?.api?.forEachNodeAfterFilter((rowNode) => {
        if (rowNode?.data) {
            visibleRows.push(rowNode?.data);
        }
    });
    return visibleRows;
}

/** As type definitions of {@link Promise.all()} regularly screws up, there are some correctly typing wrappers. */
export function all4<T1, T2, T3, T4>(
    a1: Promise<T1>,
    a2: Promise<T2>,
    a3: Promise<T3>,
    a4: Promise<T4>,
): Promise<[T1, T2, T3, T4]> {
    return Promise.all([a1, a2, a3, a4]);
}

export function all3<T1, T2, T3>(a1: Promise<T1>, a2: Promise<T2>, a3: Promise<T3>): Promise<[T1, T2, T3]> {
    return Promise.all([a1, a2, a3]);
}

export function all2<T1, T2>(a1: Promise<T1>, a2: Promise<T2>): Promise<[T1, T2]> {
    return Promise.all([a1, a2]);
}

export function createAtlasProfileLink(c: Consultant | null): string | undefined {
    if (c && c.email) {
        return CONFIG.atlas.userDetailsUrlPrefix + encodeURIComponent(c.email);
    }
}

// --- Formatters ---

export function formatConsultantName(consultant: ConsultantExpanded): string {
    return `${consultant.name} (${consultant.location?.name})`;
}

export function formatLongProjectName(project: Project): string {
    const projectDuration = project.getDuration();
    return `${project.name}  (${formatLocalDate(projectDuration.start)} - ${formatLocalDate(projectDuration.end)})`;
}

// -------------------------------- logic

export type Predicate<T> = (item: T) => boolean;
