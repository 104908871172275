import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { SimpleProject } from '../../app/model/Project';
import { DatePeriod } from '../../app/common';
import { Tag } from './types';

const PROJECT_TAG_TYPE = 'Project' as const;
const PROJECT_LIST_TAG = {
    type: PROJECT_TAG_TYPE,
    id: 'LIST',
};
const generateProjectTag = (project: SimpleProject): Tag<typeof PROJECT_TAG_TYPE> => ({
    type: PROJECT_TAG_TYPE,
    id: project.id,
});

export type GetProjectsOptions = { includeArchived: boolean; interval?: DatePeriod };
export const projectsApi = createApi({
    reducerPath: 'projectsApi',
    tagTypes: [PROJECT_TAG_TYPE],
    baseQuery,
    keepUnusedDataFor: 10,
    endpoints: (builder) => ({
        getProjects: builder.query<SimpleProject[], GetProjectsOptions | void>({
            query: (options = { includeArchived: false }) => ({
                method: 'GET',
                url: 'projects/',
                params: {
                    includeArchived: options?.includeArchived ? 'true' : 'false',
                    from: options?.interval?.start?.toString(),
                    to: options?.interval?.end?.toString(),
                },
            }),
            providesTags: (projects) => [PROJECT_LIST_TAG, ...(projects?.map(generateProjectTag) || [])],
        }),
    }),
});

export const { useGetProjectsQuery } = projectsApi;
