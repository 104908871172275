import { Selectable } from './Selectable';
import { DatePeriod, IsoDateString, parseIsoDateString } from '../common';
import { ConsultantExpanded } from './Consultant';

export interface SimpleProject {
    id: string;
    name: string;
    start: IsoDateString;
    end: IsoDateString;
    probabilityPercent: number;
    accountManagerId?: string;
    link?: string;
    absence: boolean;
    archived: boolean;
    volume: number;
    archiveReason?: string;
    potential?: number;
}

export interface Project extends SimpleProject, Selectable {
    getDuration(): DatePeriod;

    isSafe(): boolean;
}

export interface ProjectExpanded extends Project {
    accountManager?: ConsultantExpanded;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProjectImpl extends Project {}

export class ProjectImpl implements Project {
    private _duration: DatePeriod | undefined = undefined;

    constructor(projectData: SimpleProject) {
        Object.assign(this, projectData);
    }

    getDuration(): DatePeriod {
        if (!this._duration) {
            this._duration = { start: parseIsoDateString(this.start), end: parseIsoDateString(this.end) };
        }

        return this._duration;
    }

    isSafe(): boolean {
        return this.probabilityPercent >= 100;
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProjectExpandedImpl extends ProjectExpanded {}

export class ProjectExpandedImpl extends ProjectImpl implements ProjectExpanded {
    constructor(
        projectData: SimpleProject,
        public accountManager?: ConsultantExpanded,
    ) {
        super(projectData);
    }
}

export const ABSENCE_PROJECT = new ProjectExpandedImpl({
    id: 'n/a',
    name: 'n/a',
    start: '2000-01-01',
    end: '2100-12-31',
    probabilityPercent: 100,
    absence: true,
    archived: false,
    volume: 0,
});
