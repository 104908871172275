import { send } from '../send';
import { CONFIG } from '../../../config';
import { DatePeriod } from '../../common';
import { ViewPeriodType } from '../ViewPeriod';
import { ConsultantUtilizationByInterval } from '../../model/Utilization';

export async function getUtilization(
    interval: DatePeriod,
    type: ViewPeriodType,
    locationIds?: string[],
): Promise<ConsultantUtilizationByInterval> {
    const params = new URLSearchParams();

    params.set('from', interval.start.toString());
    params.set('to', interval.end.toString());
    params.set('intervalSize', type);

    if (locationIds && locationIds.length > 0) {
        params.set('locationIds', locationIds.join(','));
    }

    return send('GET', `${CONFIG.apiBaseUrl}/utilization/byConsultant?${params}`);
}
