import { configureStore, ThunkMiddleware } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import filtersReducer from './slices/filter';
import intervalSelectorReducer from './slices/intervalSelector';
import periodSelectorReducer from './slices/periodSelector';
import { locations } from './api/locations';
import { locationGroupsApi } from './api/groups';
import { regionsApi } from './api/regions';
import { consultantsApi } from './api/consultants';
import { projectsApi } from './api/projects';
import { assignmentsApi } from './api/assignments';

type SetupStoreParams = {
    middleware?: ThunkMiddleware[];
};

export function setupStore(params?: SetupStoreParams) {
    const middleware = [
        locations.middleware,
        locationGroupsApi.middleware,
        regionsApi.middleware,
        consultantsApi.middleware,
        projectsApi.middleware,
        assignmentsApi.middleware,
        ...(params?.middleware || []),
    ];
    return configureStore({
        reducer: {
            [locations.reducerPath]: locations.reducer,
            [locationGroupsApi.reducerPath]: locationGroupsApi.reducer,
            [regionsApi.reducerPath]: regionsApi.reducer,
            [consultantsApi.reducerPath]: consultantsApi.reducer,
            [projectsApi.reducerPath]: projectsApi.reducer,
            [assignmentsApi.reducerPath]: assignmentsApi.reducer,
            user: userReducer,
            filters: filtersReducer,
            interval: intervalSelectorReducer,
            periodSelector: periodSelectorReducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    });
}

export default setupStore;
export type RootState = ReturnType<ReturnType<typeof setupStore>['getState']>;
