import { Project, ProjectExpanded, ProjectExpandedImpl, ProjectImpl, SimpleProject } from '../../model/Project';
import { ConsultantExpanded } from '../../model/Consultant';
import { getConsultantsExpanded } from '../consultants/ConsultantsApi';
import { DatePeriod } from '../../common';
import { send } from '../../common/send';
import { CONFIG } from '../../../config';
import { projectsApi } from '../../../store/api/projects';
import { store } from '../../../store';

export async function getProjects(includeArchived = false, interval?: DatePeriod): Promise<ProjectExpanded[]> {
    const consultantsPromise = getConsultantsExpanded();
    return getProjectsWithConsultants(consultantsPromise, includeArchived, interval);
}

export async function getProjectsWithConsultants(
    consultantsPromise: Promise<ConsultantExpanded[]>,
    includeArchived = false,
    interval?: DatePeriod,
): Promise<ProjectExpanded[]> {
    const { data: projects, error: projectsError } = await store.dispatch(
        projectsApi.endpoints.getProjects.initiate({ includeArchived, interval }),
    );
    if (projectsError) {
        throw projectsError;
    }
    if (!projects) {
        return [];
    }
    const consultants = await consultantsPromise;
    return projects.map((p) => expandProject(p, consultants));
}

export function expandProject(project: SimpleProject, consultants: ConsultantExpanded[]): ProjectExpanded {
    let accountManager = undefined;

    if (project.accountManagerId) {
        accountManager = consultants.find((c) => c.id === project.accountManagerId);
        if (!accountManager) {
            throw `unable to resolve project ${project.id}: cannot find account manager ${project.accountManagerId}`;
        }
    }

    return new ProjectExpandedImpl(project, accountManager);
}

export async function getProject(id: string): Promise<Project> {
    return new ProjectImpl(await send('GET', `${CONFIG.apiBaseUrl}/projects/${id}`));
}

export async function createProject(project: Project): Promise<Project> {
    return new ProjectImpl(await send('POST', `${CONFIG.apiBaseUrl}/projects/`, project));
}

export async function updateProject(project: Project): Promise<Project> {
    return new ProjectImpl(await send('PUT', `${CONFIG.apiBaseUrl}/projects/${project.id}`, project));
}

export async function deleteProject(project: Project, reason: string): Promise<any> {
    // TODO: reason should be in request body
    return send('DELETE', `${CONFIG.apiBaseUrl}/projects/${project.id}?reason=${encodeURIComponent(reason)}`);
}
