import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { Region } from '../../app/model/Region';

const generateRegionTag = (region: Region) => ({ type: 'Region' as const, id: region.id });

export const regionsApi = createApi({
    reducerPath: 'regionsApi',
    tagTypes: ['Region'],
    baseQuery,
    endpoints: (builder) => ({
        getRegions: builder.query<Region[], void>({
            query: () => 'locations/regions/',
            providesTags: (regions) => [{ type: 'Region', id: 'LIST' }, ...(regions?.map(generateRegionTag) || [])],
        }),
    }),
});

export const { useGetRegionsQuery } = regionsApi;
