import React, { useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { WarningTable } from './WarningTable';
import { generateWarningByLocationModel, getWarningsData, WarningData } from '../HomeApi';
import { useAllPossibleLocations } from '../../../customHooks/allPossibleLocations';
import { useCurrentLocations } from '../../../customHooks/currentLocations';

export function WarningsContainer(): React.JSX.Element {
    const [warningData, setWarningData] = useState<WarningData>();
    const currentLocations = useCurrentLocations();
    const locations = useAllPossibleLocations();

    useEffect(() => {
        async function fetchWarningData(): Promise<void> {
            const data = await getWarningsData(locations);
            setWarningData(data);
        }
        if (locations) {
            fetchWarningData();
        }
    }, [setWarningData, locations]);

    const model = useMemo(() => {
        if (warningData) {
            return generateWarningByLocationModel(warningData, currentLocations);
        }
    }, [warningData, currentLocations]);

    const hasWarnings = useMemo(() => {
        if (model) {
            for (const location of model.getLocations()) {
                const warningsLength = model.getWarningsForLocation(location).length;
                if (warningsLength > 0) {
                    return true;
                }
            }
        }
    }, [model]);

    return (
        <Alert dismissible={false} show={true} variant="warning">
            <div>
                <Alert.Heading>Warnungen</Alert.Heading>
                {model && hasWarnings ? <WarningTable model={model} /> : null}
                {model && !hasWarnings ? <div>Keine Warnungen für ausgewählte Standorte</div> : null}
            </div>
        </Alert>
    );
}
