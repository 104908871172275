import { Consultant, ConsultantExpanded, ConsultantExpandedImpl } from '../../model/Consultant';
import { Location } from '../../model/Location';
import { sortByName } from '../../model/Selectable';
import { store } from '../../../store';
import { locations } from '../../../store/api/locations';
import { consultantsApi } from '../../../store/api/consultants';

export async function getConsultantsExpanded(): Promise<ConsultantExpanded[]> {
    const [{ data: consultantsData, error: consultantsError }, { data: locationsData, error: locationsError }] =
        await Promise.all([
            store.dispatch(consultantsApi.endpoints.getConsultants.initiate()),
            store.dispatch(locations.endpoints.getLocations.initiate()),
        ]);

    if (consultantsError) {
        throw consultantsError;
    }
    if (locationsError) {
        throw locationsError;
    }
    if (!locationsData || !consultantsData) {
        return [];
    }

    return sortByName(consultantsData.map((c) => expandConsultant(c, locationsData)));
}

export function expandConsultant(consultant: Consultant, locations: Location[]): ConsultantExpanded {
    const location = locations.find((l) => l.id === consultant.locationId);
    if (!location) {
        throw `unable to resolve consultant ${consultant.id}: cannot find location ${consultant.locationId}`;
    }

    return new ConsultantExpandedImpl(consultant, location);
}
